import { SHADOW } from '@iadvize/shadow-tokens';
import { addOrReplaceIframe, destroyIframeByID } from '../../lib/IframeManager';
import { getPlatform } from '../entry/buildUrl/getPlatformDomain';
import { getTagConfig } from '../entry/tagConfig';
import {
  getTemplateName,
  getClientId,
} from '../entry/visitorContextConfig/websiteConfiguration';
import { getStaticUrl } from '../shared/staticUrl';
import { DebugWindowType } from './types';
import { draggable } from '../shared/draggable/draggable';
import CustomEventEmitter from '../shared/utils/CustomEventEmitter';
import { AllEvents } from '../live/src/event-manager/types';
import Timer from '../shared/utils/timer';

let currentLocation: string | undefined;
let previousLocation: string | undefined;

const debug = async (
  launchdarklyClientKey: string,
  graphQLUrl: string,
  engagementApiUrl: string,
  commonConfigLanguage: string,
  browsingTimeSpentOnPageTimer: Timer,
  activeTimeSpentOnPageTimer: Timer,
): Promise<void> => {
  //__________________________________________
  destroyIframeByID('iadvize-debug');

  const closeIframe = () => {
    destroyIframeByID('iadvize-debug');
  };

  let isDraggable = false;
  const draggableInstance = draggable();

  const toggleDraggable = () => {
    const debugIframe = document.getElementById('iadvize-debug');
    if (isDraggable) {
      draggableInstance.removeDraggable(debugIframe);
    } else {
      draggableInstance.initDraggable(debugIframe, false);
    }
    isDraggable = !isDraggable;
  };

  const getVisitorData = (
    newPreviousLocation?: string,
    newCurrentLocation?: string,
  ) => {
    previousLocation =
      newPreviousLocation || previousLocation || document.referrer;
    currentLocation =
      newCurrentLocation || currentLocation || window.location.href;

    return {
      previousLocation,
      currentLocation,
      deviceWidth: window.innerWidth,
      deviceHeight: window.innerHeight,
      browsingTimeSpentOnPage: browsingTimeSpentOnPageTimer.time,
      activeTimeSpentOnPage: activeTimeSpentOnPageTimer.time,
    };
  };
  const dispatcher = CustomEventEmitter<AllEvents>('dispatcher');

  const debugGlobals: DebugWindowType = {
    iAdvize: {
      get: window.iAdvize.get,
      on: window.iAdvize.on,
      off: window.iAdvize.off,
    },
    clientId: getClientId(),
    templateName: getTemplateName(),
    getTagConfig,
    closeIframe,
    toggleDraggable,
    getPlatform,
    launchdarklyClientKey,
    graphQLUrl,
    engagementApiUrl,
    commonConfigLanguage,
    dispatcher,
    getVisitorData,
  };

  addOrReplaceIframe(
    document.body, //________________________________________________________
    debugGlobals, //_____________________________________________________
    'iadvize-debug', //__________________________
    'idzdebug', //_____________________
    window.iAdvize.curlang, //______________________________________
    {
      position: 'fixed',
      width: '60vw',
      height: '70%',
      bottom: '30px',
      left: '30px',
      zIndex: '1000000001',
      padding: '0',
      borderRadius: '8px',
      margin: '0',
      boxShadow: SHADOW.SHADOW.M.toString(),
    },
    [`${getStaticUrl()}debug.js`], //______________________________________________________
    //__________
    [`${getStaticUrl()}debug.css`],
  );
};

export default debug;
